import React, { useState, useEffect } from 'react';
import {
  useUploadUsersCheckMutation,
  useUploadUsersMutation,
  useGetUsersTemplateQuery
} from '../../slices/uploadUsersApiSlice';
import { Button } from '../../ui-kit';
import { UploadForm, UploadInput } from './UploadUsers.styles';
import { formatMessage } from '../../locale';
import { useIntlContext } from '../../locale/IntlProviderWrapper';
import ConfirmationModal from '../../ui-kit/Modal/ConfirmationModal';
import NoticeModal from '../../ui-kit/Modal/NoticeModal';
import  ModalContent  from '../../ui-kit/ModalContent/ModalContent';
import { MessageType } from '../../enum/MessageType/MessageType';

function UploadUsers() {
  const { intl } = useIntlContext();
  const [fetchTemplate, setFetchTemplate] = useState(false);
  const [file, setFile] = useState<File | null>(null);
  const [inputKey, setInputKey] = useState(Date.now());
  const [isUploading, setIsUploading] = useState<boolean | null>(false);
  const [isImportCheckDialogOpen, setIsImportCheckDialogOpen] = useState<boolean | null>(false);
  const [isValidationErrorsModalOpen, setIsValidationErrorsModalOpen] = useState(false);
  const [successMessages, setSuccessMessages] = useState<any[]>([]);
  const [errorMessages, setErrorMessages] = useState<any[]>([]);
  
  const [uploadUsersCheck] = useUploadUsersCheckMutation();
  const [uploadUsers] = useUploadUsersMutation();
  const { data: usersTemplate, refetch } = useGetUsersTemplateQuery({}, { skip: !fetchTemplate });

  useEffect(() => {
    if (fetchTemplate) {
      refetch();
    }
  }, [fetchTemplate, refetch]);

  useEffect(() => {
    if (usersTemplate) {
      const blob = new Blob([usersTemplate], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      const url = URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = 'users_template.xlsx';
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      URL.revokeObjectURL(url);
      setFetchTemplate(false);
    }
  }, [usersTemplate]);

  const handleFetchTemplate = () => {
    setFetchTemplate(true);
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      setFile(event.target.files[0]);
    }
  };

  const handleUploadFile = async () => {
    setIsUploading(true);
    try {
      const response = await uploadUsersCheck({ data: { file: file } }).unwrap();
      if (Object.keys(response.info).length === 0) {
        setIsValidationErrorsModalOpen(true);
      } else {
        setSuccessMessages(response.info);
        setIsImportCheckDialogOpen(true);
      }
    } catch (error: any) {
      if (error.data && error.data.errors) {
        setErrorMessages(error.data.errors);
        setIsValidationErrorsModalOpen(true);
      }
    } finally {
      setIsUploading(false);
    }
  };

  const handleConfirm = async () => {
    setIsImportCheckDialogOpen(false);
    try {
      await uploadUsers({ data: { file: file } }).unwrap();
      setFile(null);
      setInputKey(Date.now());
    } catch (error: any) {
      console.log(error);
    } finally {
      setIsUploading(false);
    }
  };

  const handleCancel = () => {
    setIsImportCheckDialogOpen(false);
    setFile(null);
    setInputKey(Date.now());
    setSuccessMessages([]);
  };

  const handleOk = () => {
    setIsValidationErrorsModalOpen(false);
    setFile(null);
    setInputKey(Date.now());
    setErrorMessages([]);
  }

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    setSuccessMessages([]);
    handleUploadFile();
  };
  
  return (
    <div>
      <h2 style={{ marginTop: '10px', marginBottom: '10px' }}>
      {formatMessage({ id: 'employes.upload', defaultMessage: 'Upload new users' })}
      </h2>
      <UploadForm onSubmit={handleSubmit}>
      <UploadInput key={inputKey} type="file" onChange={handleFileChange} />
      <Button appearance="primary" type="submit" disabled={isUploading || !file}>
        {isUploading
        ? formatMessage({ id: 'employes.uploading', defaultMessage: 'Uploading...' })
        : formatMessage({ id: 'employes.uploadd', defaultMessage: 'Upload' })}
      </Button>
      </UploadForm>
      <h2 style={{ marginTop: '10px', marginBottom: '10px' }}>
      {formatMessage({ id: 'employes.getTamplateTitle', defaultMessage: 'Get users template' })}
      </h2>
      <Button appearance="primary" onClick={handleFetchTemplate}>
      {formatMessage({ id: 'employes.getTemplate', defaultMessage: 'Get users template' })}
      </Button>
      {isImportCheckDialogOpen && (
      <ConfirmationModal
        header={<ModalContent type={MessageType.success} messages={successMessages} />}
        onConfirm={handleConfirm}
        onCancel={handleCancel}
      />
      )}
      {isValidationErrorsModalOpen && (
      <NoticeModal
        header={Object.keys(errorMessages).length === 0 ?  formatMessage({ id: 'modal.empty', defaultMessage: 'Nothing to update or change!' }) :  <ModalContent type={MessageType.error} messages={errorMessages} /> }
        onConfirm={handleOk}
      />
      )}
    </div>
  );
}

export default UploadUsers;
