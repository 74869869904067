import React from 'react';

interface ListProps {
    messages: string[];
}

const List: React.FC<ListProps> = ({ messages }) => {
    return (
        <div style={{ padding: '0 0px 0 10px', margin: '0 0px 0 10px' }}>
            {messages.map((msg, index) => (
                <ul key={index}>
                    <li style={{padding: 2, border: 10, listStyleType: 'initial'}}>{msg}</li>
                </ul>
            ))}
        </div>
    );
};

export default List;
